import React from 'react';
import styled from 'styled-components';
import {Pagination, Button, message} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {PlayCircle} from '@styled-icons/bootstrap/PlayCircle';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom.js';

function VideoList(props) {
  const [user, setUser] = useOutlet('user');

  const [videos, setVideos] = React.useState(null);
  const [total, setTotal] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
  });

  React.useEffect(() => {
    async function fetchVideoList() {
      AppActions.setLoading(true);
      try {
        let pIds = [];
        let products = [];

        let videos = await AppActionsEx.fetchVideoList({
          query: {},
          sort: ['-created'],
          pagination: {
            offset: (pagination.page - 1) * pagination.pageSize,
            limit: pagination.pageSize,
          },
          projection: {},
        });

        if (videos.total > 0) {
          pIds = videos.results.map((v) => v.product_id);
          products = await JStorage.fetchAllDocuments(
            'product',
            {
              _id: {$in: pIds.map((id) => ({$oid: id}))},
            },
            ['-created'],
            {name: 1, id: 1},
          );

          videos.results = [...videos.results].map((record) => {
            const targetProduct = products.find(
              (p) => p.id === record.product_id,
            );

            return {
              ...record,
              product_name: targetProduct ? targetProduct.name : '---',
            };
          });
        }

        setTotal(videos.total);
        setVideos(videos.results);
      } catch (err) {
        console.warn(err);
      } finally {
        AppActions.setLoading(false);
      }
    }

    if (user && !user.tmp) {
      fetchVideoList();
    }
  }, [user, pagination]);

  return (
    <Wrapper>
      <div className="maxwidth-container">
        <div className="container">
          <h1 className="title">北律雲-課程影片</h1>
          {videos &&
            videos.map((v, index) => (
              <div className="item" key={`product-${index}`}>
                <div>
                  <div className="name">
                    {v.pre_meta_data?.product_name || v.product_name || v.name}
                  </div>

                  {v.expired_datetime ? (
                    <div className="deadline">
                      影片觀看期限：
                      {new Date(v.expired_datetime).toLocaleString('sv')}
                    </div>
                  ) : null}
                </div>

                <Button
                  ghost
                  type="primary"
                  icon={
                    <PlayCircle
                      size={18}
                      color={
                        v.expired_datetime
                          ? new Date().getTime() > v.expired_datetime
                            ? 'gray'
                            : 'var(--primaryColor)'
                          : 'var(--primaryColor)'
                      }
                    />
                  }
                  className="play"
                  disabled={
                    v.expired_datetime
                      ? new Date().getTime() > v.expired_datetime
                      : false
                  }
                  onClick={() =>
                    AppActions.navigate(`/video?id=${v.video_id}`)
                  }>
                  <label>觀看影片</label>
                </Button>
              </div>
            ))}
        </div>
        <div className="container">
          <Pagination
            defaultCurrent={1}
            current={pagination.page}
            total={total}
            showTotal={(total) => `共 ${total} 筆`}
            onChange={(page, pageSize) => {
              setPagination({page, pageSize});
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: var(--pageBackgroundColor);

  & > .maxwidth-container {
    margin: var(--topNavBarHeight) auto 0px;
    max-width: var(--contentMaxWidth);
    width: 100%;
    min-height: 100vh;
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    & > .container {
      max-width: 910px;
      width: 100%;
      margin: 30px auto;

      & > .title {
        margin-bottom: 10px;
      }

      & > .item {
        margin-bottom: 20px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;

        & .name {
          margin: 5px;
          font-size: 1rem;
          font-weight: 600;
        }

        .deadline {
          margin: 5px;
          font-size: 0.9rem;
        }

        & > .play {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          cursor: pointer;

          & > label {
            margin-left: 5px;
          }
        }
      }
    }
  }
`;

export default withPageEntry(VideoList);
